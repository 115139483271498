import React from 'react'
import Helmet from 'react-helmet'
import {
	title,
	siteUrl,
	phone,
	description,
	thumbnail,
	favicon
} from '../../../data/config'

const SEO = ({ children, location = '' }) => {
	const structuredDataOrganization = `{ 
		"@context" : "http://schema.org",
		"@type" : "Organization",
		"legalName" : "${title}",
		"url" : "${siteUrl}",
		"logo" : "${siteUrl}/logo/logo-48.png",
		"contactPoint": [{
			"@type" : "ContactPoint",
			"telephone" : "${phone}",
			"contactType" : "customer service"
		}],
		"sameAs": [
			"https://www.facebook.com/barmej",
			"https://twitter.com/barmej",
			"https://www.instagram.com/barmej/",
			"https://www.linkedin.com/company/barmej/",
			"https://plus.google.com/+Barmej",
			"https://github.com/barmej"
		]
	}`
	return (
		<Helmet>
			<meta name="description" content={description} />
			<meta name="image" content={thumbnail} />

			<meta property="og:url" content={`${siteUrl}${location}`} />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={thumbnail} />
			{/* <meta property="fb:app_id" content={socialfacebook} /> */}

			<link rel="shortcut icon" href={favicon} />

			<meta name="twitter:card" content="summary" />
			<meta name="twitter:creator" content="https://twitter.com/Barmej" />
			<meta name="twitter:site" content="@barmej" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image:src" content={thumbnail} />
			<script type="application/ld+json">{structuredDataOrganization}</script>
			<html lang="ar" dir="rtl" />
			{ children }
		</Helmet>
	)
}

export default SEO
