import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, Card } from '../../common'
import Header from '../../components/includes/Header'
import Footer from '../../components/includes/Footer'

const Choice = ({
	nextStep,
	goToStep,
	editStep,
	data,
	score,
	current,
	choices,
	question,
	description,
	previous,
	completed,
	editSingleStep
}) => {
	const currentStep = data.find(({ step }) => step === current)

	const chooseChoice = (response, price, illustration) => {
		if (currentStep && currentStep.checked && !completed) {
			editStep({
				step: current,
				question,
				response,
				price,
				illustration,
				checked: true
			}, completed)
		} else if (completed) {
			editSingleStep({
				step: current,
				question,
				response,
				price,
				illustration,
				checked: true
			})
		} else {
			nextStep({
				step: current,
				question,
				response,
				price,
				illustration,
				checked: true
			}, completed)
		}
	}

	return (
		<React.Fragment>
			<Header score={score} step={current} previous={previous} backward={() => goToStep(current - 1)} siteTitle="Ta6beeq.io | احسب تكلفة تطوير تطبيقك خلال دقائق" />
			<Container className="start">
				<h1>
					<FormattedMessage id={`message.${question}`} />
				</h1>
				<p>
					<FormattedMessage id={`message.${description}`} />
				</p>
				<div className="cards">
					{choices.map(({
						id, icon, title, price, illustration
					}) => (
						<Card
							onClick={() => chooseChoice(title, parseFloat(price), illustration)}
							key={id}
							icon={icon}
							title={title}
							chosen={Boolean(currentStep && currentStep.response === title)}
						/>
					))}
				</div>
			</Container>
			<Footer step={current} />
		</React.Fragment>
	)
}

export default Choice
