import React from 'react'
import cx from 'classnames'
import './styles.scss'

const Card = ({
	title,
	icon,
	onClick,
	chosen
}) => (
	<div className="item">
		<div className={cx('card', { active: chosen })} onClick={onClick}>
			<div className="card-image">
				<img src={icon} alt={title} />
			</div>
			<div className="card-title">
				<h4>{title}</h4>
			</div>
		</div>
	</div>
)

export default Card
