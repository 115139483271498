import React from 'react'
import { siteUrl } from '../../../data/config'
import fbIcon from '../../../static/icons/facebook.svg'
import twitterIcon from '../../../static/icons/twitter.svg'
import whatsappIcon from '../../../static/icons/whatsapp.svg'
import './styles.scss'

const Social = ({ className = '' }) => (
	<div className={`social-buttons ${className}`}>
		<a className="social fb" href={`https://facebook.com/sharer/sharer.php?u=${siteUrl}/?new=2`} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
			<span>انشر</span>
			<img src={fbIcon} alt="Share" />
		</a>
		<a className="social twitter" href={`https://twitter.com/intent/tweet/?text=يمكنك%20الآن%20حساب%20تكلفة%20تطوير%20تطبيقك%20بكل%20سهولة%20وخلال%20دقائق%20مع%20موقع%20Ta6beeq.io&amp;url=${siteUrl}`} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
			<span>غرد</span>
			<img src={twitterIcon} alt="Tweet" />
		</a>
		<a className="social whatsapp" href="https://wa.me?text=يمكنك%20الآن%20حساب%20تكلفة%20تطوير%20تطبيقك%20بكل%20سهولة%20وخلال%20دقائق%20مع%20موقع%20https://ta6beeq.io" aria-label="Whatsapp">
			<span>انشر</span>
			<img src={whatsappIcon} alt="Whatsapp" />
		</a>
	</div>
)

export default Social
