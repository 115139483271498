import React from 'react'
import { Container, Social, Step } from '../../../common'
import './styles.scss'

const Header = ({
	score,
	backward,
	step,
	previous,
	refresh,
	goBack,
	pricing
}) => (
	<div className="header">
		<Container className={`header-container ${pricing && 'pricing-page'}`}>
			<Social className="social-header" />
			{step && <Step step={step} className="step-header" />}
			{score && <p>${score}</p>}
			{refresh && <button type="button" className="backward" onClick={refresh}>إبدأ من جديد</button>}
			{previous && <button className="backward" type="button" onClick={backward}>السؤال اﻟﺴﺎﺑﻖ</button>}
			{goBack && <button className="backward" type="button" onClick={() => goBack(9)}>عودة</button>}
		</Container>
	</div>
)

export default Header
