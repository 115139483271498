module.exports = {
	title: 'Ta6beeq.io | احسب تكلفة تطبيقك',
	googleAnalyticsID: 'UA-91998120-6',
	favicon: 'https://www.ta6beeq.io/favicon/favicon.ico',
	phone: '+965-60791018',
	themeColor: '#6496f6',
	backgroundColor: '#fff',
	siteUrl: 'https://www.ta6beeq.io/',
	description: 'احسب تكلفة تطوير تطبيقك خلال دقائق',
	thumbnail: 'https://www.ta6beeq.io/thumbnail.png'
}
