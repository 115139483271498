import React from 'react'
import { Container, Step, Social } from '../../../common'
import './styles.scss'

const Footer = ({ step, gift }) => (
	<div className="footer">
		<Container className={`${step ? 'footer-container-flex' : 'footer-container-block'} footer-container`}>
			<Social className="social-footer" />
			{gift && <p className="gift">هذا العمل <button type="button" className="backward" onClick={gift}>إهداء</button></p>}
			{step && <Step step={step} className="step-footer" />}
			<div className="right-side right-side-block">
				<a href="https://barmej.com" target="_blank" rel="noopener noreferrer">Barmej.com</a>
				<p>جميع الحقوق محفوظة {(new Date()).getFullYear()}</p>
			</div>
		</Container>
	</div>
)

export default Footer
