import React from 'react'

import andoirdIllu from '../../../static/illustrations/android.svg'
import appleIllu from '../../../static/illustrations/apple.svg'
import bothIllu from '../../../static/illustrations/both.svg'
import connectedIllu from '../../../static/illustrations/connected.svg'
import declineIllu from '../../../static/illustrations/decline.svg'
import dunnoIllu from '../../../static/illustrations/dunno.svg'
import emailIllu from '../../../static/illustrations/email.svg'
import equalityIllu from '../../../static/illustrations/equality.svg'
import fileIllu from '../../../static/illustrations/file.svg'
import freeIllu from '../../../static/illustrations/free.svg'
import goodIllu from '../../../static/illustrations/good.svg'
import iconIllu from '../../../static/illustrations/icon.svg'
import moneyIllu from '../../../static/illustrations/money.svg'
import payIllu from '../../../static/illustrations/pay.svg'
import prepayIllu from '../../../static/illustrations/prepay.svg'
import rateIllu from '../../../static/illustrations/rate.svg'
import simpleIllu from '../../../static/illustrations/simple.svg'
import socialIllu from '../../../static/illustrations/social.svg'
import startIllu from '../../../static/illustrations/start.svg'
import stockIllu from '../../../static/illustrations/stock.svg'

const icons = {
	andoirdIllu: { icon: andoirdIllu },
	appleIllu: { icon: appleIllu },
	bothIllu: { icon: bothIllu },
	connectedIllu: { icon: connectedIllu },
	declineIllu: { icon: declineIllu },
	dunnoIllu: { icon: dunnoIllu },
	emailIllu: { icon: emailIllu },
	equalityIllu: { icon: equalityIllu },
	fileIllu: { icon: fileIllu },
	freeIllu: { icon: freeIllu },
	goodIllu: { icon: goodIllu },
	iconIllu: { icon: iconIllu },
	moneyIllu: { icon: moneyIllu },
	payIllu: { icon: payIllu },
	prepayIllu: { icon: prepayIllu },
	rateIllu: { icon: rateIllu },
	simpleIllu: { icon: simpleIllu },
	socialIllu: { icon: socialIllu },
	startIllu: { icon: startIllu },
	stockIllu: { icon: stockIllu }
}

const Icon = ({ response }) => {
	const element = icons[response]
	if (element) {
		return (
			<img
				alt={response}
				src={element.icon}
			/>
		)
	}
	return null
}

export default Icon
