import React from 'react'
//import ar from 'react-intl/locale-data/ar'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/ar'
import { addLocaleData, IntlProvider } from 'react-intl'
import localeData from '../../messages/data.json'
import { SEO } from '../../common'
import './index.scss'
import './main.scss'

//addLocaleData(ar)

const Layout = ({ children }) => (
	<IntlProvider locale="ar-u-nu-latn" messages={localeData.ar}>
		<div>
			<SEO>
				<title>Ta6beeq.io</title>
			</SEO>
			{children}
		</div>
	</IntlProvider>
)

export default Layout
